exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agencies-[city]-tsx": () => import("./../../../src/pages/agencies/[city].tsx" /* webpackChunkName: "component---src-pages-agencies-[city]-tsx" */),
  "component---src-pages-agencies-index-tsx": () => import("./../../../src/pages/agencies/index.tsx" /* webpackChunkName: "component---src-pages-agencies-index-tsx" */),
  "component---src-pages-brokers-[city]-tsx": () => import("./../../../src/pages/brokers/[city].tsx" /* webpackChunkName: "component---src-pages-brokers-[city]-tsx" */),
  "component---src-pages-brokers-index-tsx": () => import("./../../../src/pages/brokers/index.tsx" /* webpackChunkName: "component---src-pages-brokers-index-tsx" */),
  "component---src-pages-discounts-tsx": () => import("./../../../src/pages/discounts.tsx" /* webpackChunkName: "component---src-pages-discounts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

